<template>
  <v-row>
    <offices-delete-dialog
    v-if="dialogDelete"
    :dialogDelete="dialogDelete"
    :officeId="officeId"
    @update:dialogDelete="dialogDelete = $event"
    @update:officeId="officeId = $event"
    ></offices-delete-dialog>
		<v-row class="pt-2" v-if="roleName === 'SYSTEM_ADMIN' || roleName === 'SYSTEM_USER'">
			<v-col cols="12">
				<v-data-table
					v-if="hospitals"
					class="pa-1 medical-staff-data-table"
					fixed-header
					dense
					:headers="headers"
					:headers-length="headers.length"
					:no-data-text="$t('no-data-available')"
					:loading-text="$t('loading')"
					:loading="loading"
					:search="appBarSearch"
					:items="filteredOrganizations"
					item-key="id"
					sort-by="id"
					group-by="medicalInstitutionData.institutionName"
					:height="pageHeight(150)"
          ref="table"
          :items-per-page="-1"
          :footer-props= "getDataTableFooterProps('officesTable')"
				>
					<template v-slot:item="{ item , index }">
						<tr :class="getRowsForDataTable(index)" @click="selectedHospitalRow(item)" >
							<td class="text-left font-weight-medium ">
								{{ item.hospitalName }}
							</td >
							<td class="text-right">
							<v-menu
								left
								bottom
								class="py-0"
								offset-y
								transition="slide-x-transition"
							>
								<template v-slot:activator="{on, attrs}">
									<v-btn
										icon
										color="main_black"
										v-bind="attrs"
										v-on="on"
									>
									<v-icon>mdi-dots-horizontal</v-icon>
									</v-btn>
								</template >
								<v-list class="px-0" dense >
									<v-list-item class="py-0 my-0" link dense @click="selectedHospitalRow(item)">
										<v-list-item-icon class="mx-1 ">
											<v-icon class="icon-img mdi-18px" color="black">mdi-eye</v-icon>
										</v-list-item-icon>
										<v-list-item-content class="font-bold font-size14">{{$t("view")}}</v-list-item-content>
									</v-list-item>
									<v-divider/>
									<v-list-item class="py-0 my-0" link dense @click="generateCSV(item)">
										<v-list-item-icon class="mx-1 ">
											<v-icon class="icon-img mdi-18px" color="main_black">mdi-file-excel</v-icon>
										</v-list-item-icon>
										<v-list-item-content class="font-weight-bold font-size14">CSV</v-list-item-content>
									</v-list-item>
									<v-list-item link dense @click="openDialog(item)">
									<v-list-item-icon class="mx-1 ">
										<v-icon v-if="roleName === 'SYSTEM_ADMIN'" color="main_red">mdi-trash-can-outline</v-icon>
									</v-list-item-icon>
									<v-list-item-content class="font-bold font-size14">{{$t("delete")}}</v-list-item-content>
									</v-list-item>
								</v-list>
							</v-menu>
							</td>
						</tr>
					</template>
					<template v-slot:[`group.header`]="{ group, headers, toggle, isOpen, items }">
						<td @click="toggle" class=" text-left expand-data-table-office " :colspan="headers.length">
							<v-row class="align-center d-flex pl-2 ">
                <v-col class="py-0 pl-0" cols="4">
                  <v-btn small icon :ref="group" :data-open="isOpen">
                    <v-icon  small v-if="isOpen" color="primary">mdi-arrow-down-drop-circle</v-icon>
                    <v-icon small v-else color="primary">mdi-arrow-right-drop-circle</v-icon>
                  </v-btn>
                  <span class="font-weight-bold ml-1 mr-1">{{ group }}</span>
                </v-col>
                <v-col cols="7" class="py-0 my-0">
                  <v-chip pill x-small color="primary" class="mx-2 text--black custom-chip-size" label>
                    {{ items.length }} {{ items.length > 1 ? $t('hospitals') : $t('hospital') }}
                  </v-chip>
                </v-col>
							</v-row>
						</td>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
		<v-row v-if="roleName === 'ORGANIZATION_ADMIN'">
			<v-col cols="12">
				<v-data-table
					v-if="filteredHospitals"
					class="pa-1 medical-staff-data-table"
					fixed-header
					dense
					:headers="headers"
					:headers-length="headers.length"
					:no-data-text="$t('no-data-available')"
					:loading-text="$t('loading')"
					:loading="loading"
					:search="appBarSearch"
					:items="hospitalsWithAdmins"
					item-key="id"
					sort-by="id"
					@click:row="selectedHospitalRow"
					:height="pageHeight(150)"
					:single-expand="true"
					:expanded.sync="expanded"
          :footer-props= "getDataTableFooterProps('officesTable')"
          :items-per-page="-1"
				>
					<template v-slot:item="{ item , index }">
						<tr :class="getRowsForDataTable(index)" @click="selectedHospitalRow(item)">
              <td class="table-font-size">
                <span>{{ item.hospitalName }}</span>
							</td>
              <td class="d-flex align-center mt-2 table-font-size">
                  <v-chip class="mx-1" v-for="(admin, index) in item.admins" :key="index" label pill x-small color="primary">{{ admin }}</v-chip>
              </td>
              <td class="text-right">
								<v-menu
									left
									bottom
									offset-y
                  class="py-0"
									transition="slide-x-transition"
								>
                  <template v-slot:activator="{on, attrs}">
                    <v-btn
                        icon
                        color="black"
                        v-bind="attrs"
                        v-on="on"
                    >
                      <v-icon class="mdi mdi-18px mdi-dots-horizontal"></v-icon>
                    </v-btn>
                  </template >
									<v-list>
										<v-list-item link dense @click="selectedHospitalRow(item)">
                    <v-list-item-icon class="mx-1 ">
                      <v-icon class="icon-img mdi-18px" color="black">mdi-eye</v-icon>
                    </v-list-item-icon>
											<v-list-item-content class="font-bold font-size14">{{ $t('view') }}</v-list-item-content>
										</v-list-item>
										<v-divider/>
									<v-list-item class="py-0 my-0" link dense @click="generateCSV(item)">
										<v-list-item-icon class="mx-1 ">
											<v-icon class="icon-img mdi-18px" color="main_black">mdi-file-document</v-icon>
										</v-list-item-icon>
										<v-list-item-content class="font-bold font-size14">CSV</v-list-item-content>
									</v-list-item>
									</v-list>
								</v-menu>
							</td>
						</tr>
					</template>
					<template v-slot:[`group.header`]="{ group, headers, toggle, isOpen, items }">
						<td class="text-left" :colspan="headers.length">
							<span>{{ group }}</span>
							<v-chip  class="mb-1 white--text" pill x-small color="primary">
							{{ items.length }}
							</v-chip>
						</td>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { showSuccessAlert, getDataTableFooterProps, getRowsForDataTable, toggleHeaders } from '@/utils/utilities';
import OfficesDeleteDialog from '@/views/DrawerDialogs/OfficesDeleteDialog.vue';

export default {
  components: {
    OfficesDeleteDialog,
  },
	data: () => ({
    loading: false,
		headerClass: 'color-header',
		expanded: [],
		dialogDelete: false,
		hospitalIdToDelete: -1,
    officeId: null,
    ecpLocationMapping: [],
	}),
	computed: {
		...mapState({
			hospitals: (state) => state.hospitals.hospitals,
			filteredHospitals: (state) => state.hospitals.filteredHospitals,
      hospitalAdminUsers: (state) => state.users.hospitalAdmins,
      allVerifiers: (state) => state.verifiers.allverifires,
			generatedCsvFile: (state) => state.billings.generatedCsvFile,
      selectedHospital: (state) => state.hospitals.selectedHospital,
    }),
		...mapGetters({
      pageHeight: 'pageHeight',
      roleName: 'authentication/getRole',
			appBarSearch: 'filterbar/getSearch',
			appBarMedicalInstitution: 'filterbar/getMedicalInstitution',
		}),
    hospitalsWithAdmins () {
      return this.hospitals.map(hospital => {
        return {
          ...hospital,
          admins: this.getHospitalAdmins(hospital),
        };
      });
    },
    headers () {
      var headers = [];
      if (this.roleName === 'SYSTEM_ADMIN' || this.roleName === 'SYSTEM_USER') {
        headers.push(
            {
              text: this.$t('name'),
              value: 'hospitalName',
              class: this.headerClass,
              width: 500,
            },
            {
              text: '',
              value: 'actions',
              class: this.headerClass,
              sortable: false,
            },
        );
      } else {
        headers.push(
        {
          text: this.$t('Office'),
          value: 'hospitalName',
            class: this.headerClass,
            width: 500,
        },
        {
          text: this.$t('admins'),
              value: 'admin',
            class: [this.headerClass],
            width: 50,
          align: 'start',
        },
        {
          text: '',
              value: 'actions',
            class: this.headerClass,
            sortable: false,
        },
        );
      }

      return headers;
    },
		filteredHospitals () {
			let calculateFileteredHospitals = this.hospitals;
			if (this.appBarMedicalInstitution !== 0 && this.appBarMedicalInstitution !== null) {
				// filter by medicalInstitutionId
				calculateFileteredHospitals = calculateFileteredHospitals.filter(h => h.medicalInstitutionId === this.appBarMedicalInstitution);
			}

			return calculateFileteredHospitals;
		},
    filteredOrganizations () {
			let calculateFileteredHospitals = this.hospitals;
			if (this.appBarMedicalInstitution !== 0 && this.appBarMedicalInstitution !== null) {
				// filter by medicalInstitutionId
				calculateFileteredHospitals = calculateFileteredHospitals.filter(h => h.medicalInstitutionData.id === this.appBarMedicalInstitution);
			}

			return calculateFileteredHospitals;
		},
	},
	async mounted () {
    if (this.roleName !== 'SYSTEM_ADMIN') {
      await this.getHospitalAmdinsUsers();
    }
		await this.getAllVerifiers();
		await this.getHospitals();
	},
	methods: {
    toggleHeaders,
    openDialog (item) {
      this.dialogDelete = true;
      this.officeId = item.id;
    },
    getRowsForDataTable,
    getDataTableFooterProps,
      getHospitalAdmins (item) {
        const admins = [];
        this.hospitalAdminUsers.forEach(hospitalData => {
          if (hospitalData.hospitalAssignedData.id === item.id) {
            admins.push(`${hospitalData.firstName} ${hospitalData.lastName}`);
          }
        });
        return admins;
      },

    async getHospitalAmdinsUsers () {
      await this.$store.dispatch('users/getHospitalAdmins').then(() => {
        this.loading = false;
      });
    },
		async getAllVerifiers () {
			if (this.allVerifiers == null) {
				await this.$store.dispatch('verifiers/getAllVerifiers').then(() => {
				});
			}
		},
		async getHospitals () {
			this.loading = true;
			await this.$store.dispatch('hospitals/getHospitals').then(() => {
				this.loading = false;
			});
      this.toggleHeaders();
		},
		async editHospital (item) {
    await this.$store.commit('hospitals/SELECTED_HOSPITAL', item);
    this.$store.commit('SET_EDIT_DETAILS_DRAWER', true);
		},
		selectedHospitalRow (item) {
			this.editHospital(item);
      this.getEcpMappingLocation();
    },
		async generateCSV (item) {
			const currentDate = new Date();
			const currentYear = currentDate.getFullYear();
			const currentMonth = currentDate.getMonth() + 1;
			const hospitalId = item.id;
			const data = { hospitalId: hospitalId, month: currentMonth, year: currentYear };
			await this.$store.dispatch('billings/generateCsvHospitalPatientsForMonth', data)
				.then(res => {
					showSuccessAlert(this.$t('success-operation'));
					const url = window.URL.createObjectURL(new Blob([this.generatedCsvFile.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', 'office-billings-csv.csv');
					document.body.appendChild(link);
					link.click();
				});
		},
    getEcpMappingLocation () {
      if (this.roleName === 'SYSTEM_ADMIN' || this.roleName === 'SYSTEM_USER') {
        this.$store.dispatch('hospitals/getEcpMappingLocation', this.selectedHospital.id).then((res) => {
          this.ecpLocationMapping = null;
          if (res.data) {
            this.ecpLocationMapping = res.data;
            this.ecpLocationMapping.userCreationIsAutomaticValue = this.ecpLocationMapping.userCreationIsAutomatic ? 1 : 0;
            this.ecpLocationMapping.residentCreationIsAutomaticValue = this.ecpLocationMapping.residentCreationIsAutomatic ? 1 : 0;
            this.ecpLocationMapping.finalizeMeasurementAutomaticallyValue = this.ecpLocationMapping.finalizeMeasurementAutomatically ? 1 : 0;
          }
        });
      }
    },
	},
};
</script>
